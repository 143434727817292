<template>
  <base-section id="about-our-product">
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <base-subheading
            size="display-1"
            title="AGE-SELEX"
            space="0"
          />

          <base-title
            class="primary--text"
            title="High Throughput Aptamer Screening"
            tag="div"
          />

          <base-body class="text-justify">
            Based on the idea of using bioactive aptamers as synthetic growth factors, we have developed a unique and patent-pending platform technology called Activity Guided Enhanced Systematic Evolution of Ligands by EXponential Enrichment (AGE-SELEX).
            AGE-SELEX allows us to rapidly identify and modify bioactive aptamers in a high-throughput manner. Conventional methods focus exclusively on the binding properties of aptamers in the search for new candidates, which may be good enough for some applications but have weaknesses in the biological context.
            In the context of living cells, the aptamer must exhibit agonistic or antagonistic activity at its binding partner or receptor.  This is what we generally refer to as bioactivity.
          </base-body>
          <base-body class="text-justify">
            AGE-SELEX combines multiple screening methods and also integrates correction functions to produce much more powerful results than conventional methods. This allows us to design aptamers based on their ability to generate specific biological signals.
            In addition, AGE-SELEX is designed as a modular method. Individual steps can be easily and flexibly adapted to a desired target structure or application.
            As a result, our method accelerates overall aptamer product development and reduces the false positive rate in candidate selection.
            <br>
            <span class="font-weight-bold">This targeted and almost universally applicable development approach for bioactive aptamers makes us unique in the market.</span>
          </base-body>
        </v-col>
      </v-row>
    </v-container>
    <base-section-heading
      color="grey lighten-2"
      icon="fal fa-cog"
      title="OUR TECHNOLOGY"
    />

    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            flat
            class="mx-auto my-0 pt-0"
            max-width="1000"
          >
            <v-img
              height="800"
              :src="require('@/assets/BindingAndActivityDetail.svg')"
              contain
            />
            <v-card-text>
              <base-title>AGE-SELEX</base-title>
              <base-body>AGE-SELEX combines SELEX binding analysis with targeted bioactivity measurements to identify high performing aptamers.</base-body>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurTechnology',

    data: () => ({
      cards: [
        {
          title: 'Bioactive Aptamers',
          subtitle: 'Highly Specific',
          text: 'Our in-house screening method allows us to identify the bioactive aptamer specifically for your needs.',
          callout: '01',
        },
        {
          title: 'Pure Products',
          subtitle: 'Clean and Lean',
          text: 'Due to their nature as a fully synthetic product we can provide you a perfectly pure and robust product. The contamination free production is an inherent result of our production process.',
          callout: '02',
        },
        {
          title: 'Scalable Production',
          subtitle: 'Best Productivity',
          text: 'Without the need of a biological producer, we can scale up the production precisly to your needs without limitations.',
          callout: '03',
        },
        {
          title: 'Affordable Prices',
          subtitle: 'Competitive Pricing',
          text: 'Our lean production cycle allows us to offer you a competetive pricing.',
          callout: '04',
        },
      ],
      ageselex: [
        {
          icon: 'fal fa-hand-holding-usd',
          title: 'Investor',
          text: 'We are currently looking for interested and motivated early investors. Please contact us for an introduction call and get more details.',
        },
        // {
        //   icon: 'fal fa-user-tie',
        //   title: 'Network',
        //   text: 'You are a professional in business dWe are interested to learn more.',
        // },
        {
          icon: 'fal fa-hands-helping',
          title: 'Collaboration',
          text: 'We are open for cooperations with academia and industry in the field of biopharmaceuticals and aptamer development.',
        },
      ],
    }),
  }
</script>
